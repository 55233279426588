import Tooltip from 'components/dist/atoms/Tooltip';
import type { LoanRoleDto } from 'src/backend';
import { AssignAvatar } from 'src/components/form-elements/assign-avatar';
import { SharedWithTeams } from 'src/components/form-elements/shared-with-teams';
import { FormElementV2ResponseDtoExtended } from 'src/types/formelement';
import { getAssignAvatarMode } from 'src/utils/form-element/get-assign-avatar-mode';
import { getElementSharedInfoTeams } from 'src/utils/form-element/get-element-shared-info-teams';
import { mapSharedInfoToAssignListItem } from 'src/utils/form-element/map-shared-info-to-assign-list-item';

import { TeamMemberSelectListAssignedListItem } from '../../team-member-select-list/team-member-select-list.types';

interface FormElementRowAssignProps {
    isDropdownVisible: boolean;
    formElement: FormElementV2ResponseDtoExtended;
    loggedInUserId: string;
    loanRoles: LoanRoleDto[];
    onAssignClick: () => void;
}

export const FormElementRowAssign = (props: FormElementRowAssignProps) => {

    const assignedList: TeamMemberSelectListAssignedListItem[] = mapSharedInfoToAssignListItem({
        loanRoles: props.loanRoles,
        sharedInfo: props.formElement.sharedInfo,
        loggedInUserId: props.loggedInUserId
    });

    const teams = getElementSharedInfoTeams(assignedList);

    return <Tooltip>
        <Tooltip.Trigger asChild>
            <button
                onClick={() => props.onAssignClick()}
                aria-label="Assign"
                aria-hidden={props.isDropdownVisible}
                className='hidden lg:block aria-hidden:opacity-0 data-active:group-hover:opacity-100'
            >
                <AssignAvatar
                    user={assignedList[0]?.user}
                    role={assignedList[0]?.role}
                    mode={getAssignAvatarMode(props.formElement.sharedInfo)} />
            </button>
        </Tooltip.Trigger>
        <Tooltip.Portal>
            <Tooltip.Content
                aria-hidden={props.isDropdownVisible}
                align="end"
                side="bottom"
                alignOffset={0}
                className="border-0 z-dialog p-0">
                {teams.length > 0 && <SharedWithTeams
                    loanRoles={props.loanRoles}
                    sharedInfo={props.formElement.sharedInfo}
                />}
                {teams.length === 0 && <div className='p-2'>Assign</div>}
            </Tooltip.Content>
        </Tooltip.Portal>
    </Tooltip>
}