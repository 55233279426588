import { j as e } from "./jsx-runtime-B6kdoens.js";
import { forwardRef as D } from "react";
import "./Accordion-C-bYsxc8.js";
import "./Avatar-95vFNu6x.js";
import "./Badge-C0bj4DFU.js";
import { B as p } from "./Button-ChOUYHf0.js";
import { D as c } from "./DropdownMenu-DpOOYGFZ.js";
import { I as t } from "./Icon-BmXCLOWx.js";
import "./Input-1P9Hc4eP.js";
import "./Label-QGUB-Cyk.js";
import { P as M } from "./ProgressBar-CXHOfwa9.js";
import { c as F } from "./utils-CJ9afRe1.js";
import "./Stack-C1p7gIIp.js";
import "./Switch-DM4pM7wx.js";
import { T as r } from "./Text-DyXxCJgw.js";
import "./Toast-DdHjWWF8.js";
import "./Toggle-Cn0qt44_.js";
import { T as a } from "./Tooltip-Dy7rqQaX.js";
import { C as T } from "./Checkbox.component-BU-ZYR_T.js";
import { D as I } from "./Drawer.component-wp_dIZNK.js";
import { u as P } from "./useMediaQuery-BLIs2rKV.js";
import { F as u } from "./FileIcon.component-CMrJuXAk.js";
const z = (n) => n.isDesktop ? {
  withPortal: n.withPortal,
  align: "start",
  side: "bottom",
  alignOffset: 0
} : {}, ie = D((n, g) => {
  const o = P("(min-width: 768px)"), {
    checked: s,
    isDragging: f,
    onCancelUpload: j,
    onCheckFilesFromOrToFile: v,
    onMove: l,
    onDelete: w,
    onOpenInNewTab: k,
    onDoubleClick: m,
    onClick: h,
    onRename: b,
    file: i,
    withPortal: N,
    onCheckChange: C,
    ...y
  } = n, x = o ? c : I;
  return /* @__PURE__ */ e.jsxs(
    "div",
    {
      className: F(
        "flex  px-1 gap-1 bg-black-10 hover:bg-black-20 group rounded-md transition-colors items-center",
        {
          "bg-black-20": s,
          "opacity-10": f
        }
      ),
      children: [
        /* @__PURE__ */ e.jsxs(
          "div",
          {
            className: "flex flex-col flex-1 overflow-hidden h-11 py-1.5",
            ref: g,
            ...y,
            children: [
              /* @__PURE__ */ e.jsxs("div", { className: "flex items-stretch gap-1 flex-1", children: [
                /* @__PURE__ */ e.jsx(
                  "button",
                  {
                    className: "hidden sm:flex items-center cursor-move text-gray-neutral-100  opacity-0 h-7 group-hover:opacity-100 transition-opacity",
                    children: /* @__PURE__ */ e.jsx(t, { name: "DragHandle", width: 8, height: 14, className: "shrink-0" })
                  }
                ),
                /* @__PURE__ */ e.jsxs(
                  "div",
                  {
                    className: "group/icon w-5 shrink-0 flex justify-center h-7 items-center",
                    children: [
                      /* @__PURE__ */ e.jsx(
                        "div",
                        {
                          "data-active": s,
                          "data-hide": !s,
                          className: 'group-hover/icon:hidden [&[data-hidden="true"]_path]:grayscale [&[data-hidden="true"]_path]:opacity-50 data-[active=true]:hidden',
                          children: /* @__PURE__ */ e.jsx(u, { fileName: i.documentName, className: "shrink-0" })
                        }
                      ),
                      /* @__PURE__ */ e.jsx(
                        "div",
                        {
                          "data-ui": s ? "active" : "",
                          className: "hidden group-hover/icon:flex data-active:flex",
                          children: /* @__PURE__ */ e.jsx(
                            T,
                            {
                              className: "bg-white w-4 h-4",
                              checked: s,
                              "data-testid": "multi-select-checkbox",
                              onClick: (d) => {
                                d.shiftKey && (v(i), d.preventDefault());
                              },
                              onCheckedChange: (d) => C(d, i)
                            }
                          )
                        }
                      )
                    ]
                  }
                ),
                /* @__PURE__ */ e.jsx(a.Provider, { children: /* @__PURE__ */ e.jsxs(
                  a,
                  {
                    delayDuration: 1e3,
                    children: [
                      /* @__PURE__ */ e.jsx(a.Trigger, { asChild: !0, children: /* @__PURE__ */ e.jsx(
                        r,
                        {
                          select: !1,
                          tabIndex: 0,
                          role: "button",
                          onDoubleClick: () => m == null ? void 0 : m(i),
                          onClick: () => h == null ? void 0 : h(i),
                          size: "sm",
                          className: "flex-1 inline-flex items-center min-w-0",
                          children: /* @__PURE__ */ e.jsx("span", { className: "truncate", children: i.title })
                        }
                      ) }),
                      /* @__PURE__ */ e.jsx(a.Portal, { children: /* @__PURE__ */ e.jsx(a.Content, { children: i.title }) })
                    ]
                  }
                ) })
              ] }),
              i.uploading && /* @__PURE__ */ e.jsx(
                M,
                {
                  height: "h-1",
                  progressBackGroundColor: "bg-gray-neutral-35",
                  className: "ml-9 flex-1 flex",
                  showLegend: !1,
                  items: [
                    {
                      title: "",
                      value: i.uploadProgress
                    }
                  ]
                }
              )
            ]
          }
        ),
        i.uploading && /* @__PURE__ */ e.jsx(
          p,
          {
            title: "Cancel upload",
            "aria-label": "Cancel upload",
            onClick: () => j(i),
            variant: "ghost",
            className: "inline-flex items-center",
            size: "sm",
            children: /* @__PURE__ */ e.jsx(t, { strokeWidth: 1.5, name: "Cancel", width: 20, height: 20 })
          }
        ),
        !i.uploading && /* @__PURE__ */ e.jsxs("div", { className: "sm:min-w-0 sm:w-0 justify-end group-hover:w-auto flex items-center overflow-hidden gap-2 shrink-0", children: [
          l && /* @__PURE__ */ e.jsx(
            p,
            {
              title: "Move file",
              "aria-label": "Move file",
              onClick: () => l(i),
              variant: "secondary",
              className: "hidden sm:inline-flex rounded-full w-6 h-6 bg-gray-neutral-60 p-0.5",
              children: /* @__PURE__ */ e.jsx(t, { strokeWidth: 1.5, name: "FolderMoveUp", width: 17, height: 14 })
            }
          ),
          /* @__PURE__ */ e.jsxs(
            x,
            {
              modal: !o,
              children: [
                /* @__PURE__ */ e.jsx(x.Trigger, { asChild: !0, children: /* @__PURE__ */ e.jsx(
                  p,
                  {
                    title: "More options",
                    "aria-label": "More options",
                    variant: "secondary",
                    className: "rounded-full w-6 h-6 bg-transparent sm:bg-gray-neutral-60 p-0.5 inline-flex",
                    children: /* @__PURE__ */ e.jsx(t, { strokeWidth: 2, name: "MoreVert", width: 20, height: 20 })
                  }
                ) }),
                /* @__PURE__ */ e.jsxs(
                  x.Content,
                  {
                    ...z({ isDesktop: o, withPortal: N }),
                    className: "z-dialog gap-2 min-w-72",
                    children: [
                      !o && /* @__PURE__ */ e.jsxs(e.Fragment, { children: [
                        /* @__PURE__ */ e.jsxs(
                          "div",
                          {
                            className: "flex gap-2 h-9items-center rounded-md px-2",
                            children: [
                              /* @__PURE__ */ e.jsx(u, { fileName: i.documentName, className: "shrink-0" }),
                              /* @__PURE__ */ e.jsx(r, { size: "sm", truncate: !0, className: "flex-1", children: i.title })
                            ]
                          }
                        ),
                        /* @__PURE__ */ e.jsx(c.Separator, {})
                      ] }),
                      /* @__PURE__ */ e.jsxs(
                        "div",
                        {
                          tabIndex: 0,
                          role: "menuitem",
                          onClick: () => k(i),
                          className: "gap-2 h-9 cursor-pointer hover:bg-black-10 flex items-center rounded-md px-2",
                          children: [
                            /* @__PURE__ */ e.jsx(t, { name: "MultiWindow", strokeWidth: 2, width: 18, height: 18 }),
                            " ",
                            /* @__PURE__ */ e.jsx(r, { weight: "medium", size: "sm", children: "Open in New Tab" })
                          ]
                        }
                      ),
                      /* @__PURE__ */ e.jsxs(
                        "div",
                        {
                          tabIndex: 0,
                          role: "menuitem",
                          onClick: () => b(i),
                          className: "gap-2 h-9 cursor-pointer hover:bg-black-10 flex items-center rounded-md px-2",
                          children: [
                            /* @__PURE__ */ e.jsx(t, { name: "Edit", strokeWidth: 2, width: 18, height: 18 }),
                            " ",
                            /* @__PURE__ */ e.jsx(r, { weight: "medium", size: "sm", children: "Rename" })
                          ]
                        }
                      ),
                      l && /* @__PURE__ */ e.jsxs(e.Fragment, { children: [
                        /* @__PURE__ */ e.jsx(c.Separator, {}),
                        /* @__PURE__ */ e.jsxs(
                          "div",
                          {
                            tabIndex: 0,
                            role: "menuitem",
                            onClick: () => l(i),
                            className: "gap-2 h-9 cursor-pointer hover:bg-black-10 flex items-center rounded-md px-2",
                            children: [
                              /* @__PURE__ */ e.jsx(
                                t,
                                {
                                  name: "FolderMoveUp",
                                  strokeWidth: 2,
                                  width: 18,
                                  height: 18
                                }
                              ),
                              /* @__PURE__ */ e.jsx(r, { weight: "medium", size: "sm", children: "Move" })
                            ]
                          }
                        )
                      ] }),
                      /* @__PURE__ */ e.jsx(c.Separator, {}),
                      /* @__PURE__ */ e.jsxs(
                        "div",
                        {
                          tabIndex: 0,
                          role: "menuitem",
                          onClick: () => w(i),
                          className: "gap-2 h-9 cursor-pointer hover:bg-black-10 flex items-center rounded-md px-2 text-destructive",
                          children: [
                            /* @__PURE__ */ e.jsx(t, { name: "Trash", strokeWidth: 2, width: 18, height: 18 }),
                            " ",
                            /* @__PURE__ */ e.jsx(r, { weight: "medium", size: "sm", variant: "inherit", children: "Delete" })
                          ]
                        }
                      )
                    ]
                  }
                )
              ]
            }
          )
        ] })
      ]
    }
  );
});
export {
  ie as S
};
